import React, { useState, useMemo } from 'react'
import classNames from 'classnames'
import { BLOCKS } from '@contentful/rich-text-types'
import ButtonBase from '../ButtonBase'
import TextBlock, { Props as TextBlockProps } from '../TextBlock/TextBlock'
import useMeasure from '@/services/hooks/useMeasure'
import { ChevronDown } from '@/components/svg'
import Type from '../Type'

interface Props extends TextBlockProps {
  lines: number
  className?: string
  textClassName?: string
}

const ExpandableTextBlock: React.FC<Props> = ({
  lines,
  document,
  className,
  textClassName,
}) => {
  const [showingMore, setShowingMore] = useState(false)
  const [ref, rect] = useMeasure<HTMLDivElement>()

  const textOverflows = useMemo(() => {
    if (ref?.current && rect?.height) {
      return ref.current.scrollHeight > rect.height
    }
    return false
  }, [ref, rect])

  const optionsOverrides = useMemo(
    () => ({
      [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
        return (
          <Type
            as="p"
            variant={['paragraph-sm', 'md:paragraph-md']}
            className={classNames(textClassName, 'inline')}
          >
            {children}
          </Type>
        )
      },
    }),
    [textClassName]
  )

  const style = useMemo(() => {
    if (!showingMore) {
      return {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lines,
      } as const
    }
  }, [showingMore, lines])

  return (
    <div className={className}>
      <div className="relative flex flex-col">
        <div ref={ref} style={style}>
          <TextBlock document={document} optionOverrides={optionsOverrides} />
        </div>

        {(showingMore || textOverflows) && (
          <div
            className={classNames('flex justify-center', {
              '-mt-3': showingMore,
              'mt-1': !showingMore,
            })}
          >
            <ButtonBase
              className={classNames(
                { 'rotate-180': showingMore },
                textClassName
              )}
              onClick={() => setShowingMore(!showingMore)}
              aria-label={showingMore ? 'Show Less' : 'Show More'}
            >
              <ChevronDown className="w-6 h-6" />
            </ButtonBase>
          </div>
        )}
      </div>
    </div>
  )
}

export default ExpandableTextBlock
