import React, { useCallback } from 'react'
import classNames from 'classnames'
import { ButtonBase } from '@/components/common'
import { QuestionOutline } from '@/components/svg'

declare global {
  interface Window {
    zE: (target: string, action: string) => void
  }
}

interface Props {
  text?: string
  className?: string
}

const ZendeskLauncher: React.FC<Props> = ({ text, className }) => {
  const launchWidget = useCallback(() => {
    window.zE('webWidget', 'open')
  }, [])

  return (
    <ButtonBase
      onClick={launchWidget}
      className={classNames(
        {
          'bg-gray-1 hover:bg-gray-2 text-white transition-all': true,
        },
        className
      )}
      type="button"
      aria-label="Contact support"
    >
      <div className="flex justify-center items-center">
        <QuestionOutline
          className={classNames({ 'w-6': true, 'md:mr-2': !!text })}
        />
        <span className="hidden md:block">{text}</span>
      </div>
    </ButtonBase>
  )
}

export default ZendeskLauncher
