import { Campaign } from '@/services/api/campaign'
import { Chat } from '@/services/api/chatbot'
import { useSubscribeChat } from '@/services/hooks/useSubscribeChat'
import {
  nameUtil,
  numberUtil,
  report,
  resolveErrorMessage,
  scrollUtil,
} from '@/utils'
import classNames from 'classnames'
import React, {
  ReactEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Text from '../Text/Text'
import Message from './Message'
import Button from '../Button/Button'
import UserAvatar from './UserAvatar'
import { ChatSubmitButton } from '@/components/svg'
import useAuth from '@/services/hooks/useAuth'
import useParty from '@/services/hooks/useParty'
import useChat from '@/services/hooks/useChat'
import useABTest from '@/services/hooks/useABTest'

const footerStyle = { boxShadow: '0px -4px 12px 0px rgba(0, 0, 0, 0.25)' }

interface Props {
  campaign: Campaign
  className?: string
  livestreamState?: 'pre' | 'live' | 'post'
  chatTranscriptUrl?: string | null
}

const LivestreamChat: React.FC<Props> = ({
  campaign,
  className,
  livestreamState = 'pre',
  chatTranscriptUrl,
}) => {
  const [isFollowing, setFollowing] = useState(true)
  const [currentMessage, setCurrentMessage] = useState('')
  const [messages, setMessages] = useState<Chat[]>([])
  const scrollPositionRef = useRef<number>(0)
  const scrollableContainer = useRef<HTMLDivElement>(null)
  const { isError, data: historicalChat } = useChat(campaign.slug)
  const { user, openModal, startAuth } = useAuth()
  const { party, isLoading: isPartyLoading } = useParty()
  const [decision] = useABTest('ellis_island_oauth2')

  const userName = useMemo(
    () => nameUtil.getDisplayName(party, user),
    [user, party]
  )

  const {
    error: subscribeError,
    chatStatus,
    sendMessage,
    totalViewers,
  } = useSubscribeChat({
    chatCode: campaign.chatRoomId,
    setMessages,
  })

  useEffect(() => {
    if (!historicalChat) return

    setMessages(historicalChat)
  }, [historicalChat])

  useEffect(() => {
    const element = scrollableContainer.current
    if (element && isFollowing) {
      scrollPositionRef.current = element.scrollHeight
      scrollUtil.scrollToBottomOfElement(element)
    }
  }, [messages, isFollowing])

  useEffect(() => {
    chatTranscriptUrl &&
      fetch(chatTranscriptUrl)
        .then((res) => res.json())
        .then((transcript: Chat[]) => {
          setMessages(transcript)
        })
  }, [chatTranscriptUrl])

  const onScroll = () => {
    const chatContainer = scrollableContainer.current

    if (!chatContainer) {
      return
    }

    if (
      chatContainer.scrollTop + chatContainer.clientHeight >=
      scrollPositionRef.current
    ) {
      setFollowing(true)
    } else {
      setFollowing(false)
    }
  }

  const handleSubmit = useCallback<ReactEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault()
      if (!user?.isValidEmail && decision.variationKey === 'on') {
        openModal('eiVerifyAccount')
      } else {
        sendMessage(currentMessage)
        setCurrentMessage('')
      }
    },
    [currentMessage, sendMessage]
  )

  const stopFollowing = useCallback(() => {
    setFollowing(false)
  }, [setFollowing])

  if (isError) {
    report.error(resolveErrorMessage(isError as Error))
    return null
  }
  // report it but still show recent chat messages, i.e. continue rendering
  if (subscribeError) {
    report.error(resolveErrorMessage(subscribeError))
  }

  return (
    <div
      className={classNames(
        'flex flex-col overflow-hidden bg-gray-1 rounded-lg border border-gray-3 h-full',
        className
      )}
    >
      <div className="flex items-center justify-between p-2 text-white">
        <Text as="h3" preset="heading.xs">
          {chatTranscriptUrl && livestreamState === 'post'
            ? 'Chat Transcript'
            : 'Chat'}
          <span className="font-light opacity-50 ml-2">
            ({livestreamState === 'post' ? 'offline' : chatStatus})
          </span>
        </Text>
        {chatStatus === 'online' && totalViewers > 0 && (
          <Text
            as="span"
            preset="body.md"
            className="bg-black px-2 py-1 rounded-lg"
          >{`${numberUtil.abbreviateNumber(totalViewers)} watching now`}</Text>
        )}
      </div>
      {livestreamState === 'pre' && chatStatus === 'offline' ? (
        <div className="p-4 pb-12 flex flex-col flex-1 items-center justify-center text-white text-center">
          <Text as="p" preset="heading.md" className="mb-2">
            We are glad you are here!
          </Text>
          <Text as="p" preset="body.sm" className="text-gray-10">
            Chat will be activated a few minutes before the livestream event
            begins.
          </Text>
        </div>
      ) : (
        <>
          <div
            className="flex-1 overflow-y-scroll px-4 py-2 scrollbar"
            data-testid={
              isFollowing
                ? 'chatContainerFollowing'
                : 'chatContainerNotFollowing'
            }
            ref={scrollableContainer}
            onScroll={onScroll}
            onClick={stopFollowing}
            onTouchMove={stopFollowing}
            onMouseDown={stopFollowing}
          >
            {messages.map((message) => (
              <Message message={message} key={message.publicId} />
            ))}
          </div>

          {chatStatus === 'online' && livestreamState !== 'post' && !user && (
            <div className="p-4" style={footerStyle}>
              <Button
                variant="secondary"
                color="white"
                className="w-full normal-case text-sm font-bold"
                onClick={() => {
                  if (decision.variationKey === 'on') {
                    startAuth()
                  } else {
                    openModal()
                  }
                }}
              >
                Login to comment
              </Button>
            </div>
          )}

          {livestreamState !== 'post' &&
            chatStatus === 'online' &&
            !!user &&
            !isPartyLoading && (
              <form
                onSubmit={handleSubmit}
                className="relative p-4"
                style={footerStyle}
              >
                <UserAvatar
                  name={userName}
                  className="absolute top-[calc(50%-1rem)] left-6"
                />
                <input
                  type="text"
                  placeholder="Type your message"
                  className="bg-black py-3 px-12 rounded-lg w-full text-white text-[0.8125rem]"
                  value={currentMessage}
                  onChange={(e) => {
                    setCurrentMessage(e.target.value)
                  }}
                  onFocus={() => {
                    // scrolls to bottom when focused
                    setFollowing(true)
                  }}
                />
                <button
                  data-testid="chatSubmitButton"
                  disabled={!currentMessage}
                >
                  <ChatSubmitButton
                    className="absolute top-[calc(50%-16px)] right-6"
                    disabled={!currentMessage}
                  />
                </button>
              </form>
            )}

          {livestreamState === 'post' && (
            <Text
              as="p"
              preset="body.sm"
              className="py-4 px-6 text-gray-6 text-center"
              style={footerStyle}
            >
              This chat was previously recorded during the livestream{''}
              {chatTranscriptUrl
                ? '.'
                : ' and a transcript can be made available upon request.'}
            </Text>
          )}
        </>
      )}
    </div>
  )
}

export default LivestreamChat
