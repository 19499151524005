import useApi from '@/services/hooks/useApiV2'
import { getInvestmentChat, InvestmentChat } from '@/services/api/chatbot'

const useInvestmentChatbot = (slug: string) => {
  const { data, isLoading, isError } = useApi<InvestmentChat[]>({
    key: `investment-chatbot/${slug}`,
    request: () => getInvestmentChat(slug),
  })

  return {
    data,
    isLoading,
    isError,
  }
}

export default useInvestmentChatbot
