import React from 'react'
import classNames from 'classnames'
import config from './config'
import { TextPropsType } from './types'
import { report } from '@/utils'

const getClassName = (presets: string[]) =>
  presets
    .reduce((acc: string[], cur: string) => {
      const [prefix, presetName] =
        cur.split(':').length === 2 ? cur.split(':') : [null, cur]

      const classString = config[presetName]

      if (typeof classString !== 'string') {
        throw new Error(`Invalid preset: ${presetName}`)
      }

      if (prefix) {
        const prefixedClassString = classString
          .split(' ')
          .map((val) => `${prefix}:${val}`)
          .join(' ')
        acc.push(prefixedClassString)
      } else {
        acc.push(classString)
      }

      return acc
    }, [])
    .join(' ')

/**
 * @deprecated Use the { Type } component from '@/components/common'
 */

const Text = ({
  as: Tag,
  preset,
  className,
  children,
  ...rest
}: TextPropsType) => {
  let presetClassName

  try {
    presetClassName = getClassName(preset.split(' '))
  } catch (e) {
    report.error(e as Error)
    return null
  }

  return (
    <Tag
      className={classNames({ [presetClassName]: true }, className)}
      {...rest}
    >
      {children}
    </Tag>
  )
}

export default Text
