import React, { Fragment, useCallback } from 'react'
import classNames from 'classnames'
import { Dialog, Transition } from '@headlessui/react'

const fadeIn = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}

const scaleIn = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0 scale-95',
  enterTo: 'opacity-100 scale-100',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100 scale-100',
  leaveTo: 'opacity-0 scale-95',
}

const slideUp = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0 translate-y-full',
  enterTo: 'opacity-100 translate-y-0',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100 translate-y-0',
  leaveTo: 'translate-y-full',
}

const transitionMap = { fadeIn, scaleIn, slideUp }

export interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  disableAutoClose?: boolean
  overlayColor?: string
  transition?: 'fadeIn' | 'scaleIn' | 'slideUp'
  zIndex?: string
  children: React.ReactNode
}

const Modal = ({
  isOpen,
  setIsOpen,
  disableAutoClose = false,
  overlayColor = 'rgba(0,0,0,0.5)',
  transition = 'scaleIn',
  zIndex = '2000',
  children,
}: Props) => {
  const handleClose = useCallback(() => {
    if (disableAutoClose) return
    setIsOpen(false)
  }, [setIsOpen, disableAutoClose])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames({
          'fixed inset-0 flex flex-col font-whitney transform': true,
          'items-center justify-center overflow-y-auto':
            transition !== 'slideUp',
          'justify-end': transition === 'slideUp',
        })}
        style={{ zIndex }}
        onClose={handleClose}
      >
        <div className="max-w-full">
          <Transition.Child {...fadeIn} as="div">
            <Dialog.Overlay
              className="absolute inset-0 transform transition-all"
              style={{ backgroundColor: overlayColor }}
              aria-label="Modal Overlay"
            />
          </Transition.Child>

          <Transition.Child
            as="div"
            {...transitionMap[transition]}
            className="relative max-w-full"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

Modal.Title = Dialog.Title

export default Modal
