import React from 'react'
import classNames from 'classnames'
import { ToastNotification as Notification } from '@/components/context/NotificationsContext/NotificationsContext'
import useNotifications from '@/services/hooks/useNotifications'
import ToastNotification from '../ToastNotification'
import { Button, Portal } from '@/components/common'
import { Close } from '@/components/svg'

export const ToastNotificationList = () => {
  const { notifications, removeNotification, clearNotifications } =
    useNotifications()
  const toasts = notifications.filter(
    (n) => n.type === 'toast'
  ) as Notification[]

  return (
    <Portal>
      <div
        className={classNames({
          'fixed bottom-0 left-0 z-[9999] flex flex-col-reverse px-4 transform transition-all duration-300 ease-out':
            true,
          'translate-y-full pointer-events-none': toasts.length === 0,
        })}
      >
        {toasts.map((toast) => (
          <ToastNotification
            key={toast.id}
            {...toast}
            handleRemove={() => removeNotification(toast.id)}
          />
        ))}
        {toasts.length > 1 && (
          <div className="flex justify-end mb-3">
            <Button
              size="sm"
              onClick={() => clearNotifications('toast')}
              className="flex items-center"
            >
              <span className="leading-none">Clear All</span>
              <Close className="w-5 h-5 text-white ml-2 mt-[-1px] mr-[-2px]" />
            </Button>
          </div>
        )}
      </div>
    </Portal>
  )
}

export default ToastNotificationList
