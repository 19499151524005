import { casingUtil } from '@/utils'
import { ServerIVA, IVAForm, IVA } from './types'
import { resolveBirthdayPayload } from '../resolvers'

export const resolveIVADown = (serverIVA: ServerIVA): IVA => {
  const {
    id,
    party,
    entity,
    investment,
    requireAddress,
    requireDob,
    requireDriversLicense,
    requireName,
    requireSsn,
    requireOwnerInfo,
    requireProofOfAddress,
    requireProofOfEin,
    requireProofOfSsn,
    status,
    ...rest
  } = casingUtil.snakeToCamel(serverIVA)

  return {
    ...rest,
    id,
    partyId: party,
    status,
    entityId: entity,
    investmentId: investment,
    errors: {
      requireAddress,
      requireDob,
      requireDriversLicense,
      requireName,
      requireSsn,
      requireOwnerInfo,
      requireProofOfAddress,
      requireProofOfEin,
      requireProofOfSsn,
    },
  }
}

export const resolveIVAUp = (form: IVAForm) => {
  const formData = new FormData()
  const resolvedForm = { ...form }

  if (resolvedForm.dateOfBirth) {
    resolvedForm.dateOfBirth = resolveBirthdayPayload(resolvedForm.dateOfBirth)
  }

  Object.entries(resolvedForm).forEach(([name, value]) => {
    if (
      name === 'proofOfSsn' ||
      name === 'proofOfAddress' ||
      name === 'driversLicense' ||
      name === 'ownerInfo' ||
      name === 'proofOfEin'
    ) {
      const fileObj = Array.isArray(value) ? value[0] : value
      formData.append(casingUtil.camelToSnakeStr(name), fileObj, fileObj.name)
    } else {
      formData.append(casingUtil.camelToSnakeStr(name), value)
    }
  })

  return formData
}
