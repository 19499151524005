import React from 'react'
import classNames from 'classnames'
import { formatMoney } from '@/utils'
import FormField from '@/components/common/form/FormField'
import Type from '@/components/common/Type'
import { Perk } from '@/services/api/campaign'
import { SnakeToCamel } from '@/services/api/types'
import ABTester, { Experiment } from '@/components/ABTester'
import { CheckCircle } from '@/components/svg'

interface Props {
  className?: string
  nameInCredits?: string
  perk: SnakeToCamel<Perk>
  qualified?: boolean
  showAvailable?: boolean
}

const PerkCard: React.FC<Props> = ({
  className,
  nameInCredits,
  perk,
  qualified,
  showAvailable,
}) => {
  const {
    claimed,
    descriptionLong,
    descriptionShort,
    limit,
    name,
    qualifyingInvestmentAmount,
  } = perk
  const clampClaimed = limit && claimed !== null ? Math.min(claimed, limit) : 0
  const clampAvailable = limit ? limit - clampClaimed : 0
  const isQualifiedAndWithinLimit =
    (qualified && !limit) || (qualified && limit && clampAvailable > 0)

  return (
    <div
      className={classNames(
        'bg-core-white relative rounded-[4px] border-2 p-4 text-core-gray-900',
        {
          'border-core-oxide': isQualifiedAndWithinLimit,
          'border-core-gray-300': !isQualifiedAndWithinLimit,
        },
        className
      )}
    >
      {isQualifiedAndWithinLimit && (
        <CheckCircle
          className="text-core-oxide absolute top-4 right-4 w-5 h-5"
          data-testid="qualified-check"
        />
      )}

      {limit && clampAvailable === 0 && (
        <Type
          as="span"
          variant="caption-sm"
          className="bg-core-warning-500 text-core-gray-950 py-0.5 px-1 rounded-sm mb-2 inline-block"
        >
          100% claimed
        </Type>
      )}

      <Type
        as="h4"
        variant="title-lg"
        className="font-bold leading-normal tracking-tighter mb-1"
      >
        {formatMoney(qualifyingInvestmentAmount)} or more
      </Type>
      <Type
        as="p"
        variant="title-sm"
        className="font-semibold leading-tight mb-2"
      >
        {descriptionShort}
      </Type>
      <Type as="p" variant="paragraph-md" className="text-core-gray-800">
        {descriptionLong}
      </Type>

      {qualified && name.toLowerCase() === 'name in the credits' && (
        // display FormToggleInput for name in the credits
        <div className="bg-white py-2 mt-4">
          <FormField
            displayName="Name to display in credits"
            name="investment.nameInCredits"
          />
          <FormField
            className="mt-4"
            type="toggle"
            name="investment.showNameInCredits"
            text={`Allow the name, ${nameInCredits}, to be included in the credits. Select "NO" to remain anonymous.`}
          />
        </div>
      )}

      {!!limit && (
        <div className="mt-5">
          <Type
            as="span"
            variant="paragraph-md"
            className="bg-core-gray-100 text-core-gray-700 leading-loose p-1 rounded-lg mb-2 inline-block"
          >
            {showAvailable
              ? `${clampAvailable.toLocaleString()}/${limit.toLocaleString()} available*`
              : `${clampClaimed.toLocaleString()}/${limit.toLocaleString()} claimed*`}
          </Type>
          <Type as="p" variant="caption-sm" className="text-core-gray-600">
            *This is a limited perk subject to availability. Submitting your
            investment doesn’t ensure you will get this perk.
          </Type>
        </div>
      )}
    </div>
  )
}

const ABPerkCard: React.FC<Props> = (props) => {
  if (!props.perk.limit) return <PerkCard {...props} />

  return (
    <ABTester name="claimed_vs_available_perks" waitForClientReady>
      {({ decision }: Experiment<'available' | 'claimed'>) => (
        <PerkCard
          {...props}
          showAvailable={decision.variationKey === 'available'}
        />
      )}
    </ABTester>
  )
}

export default ABPerkCard
