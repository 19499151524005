import { Chat } from '@/services/api/chatbot'
import { Transition } from '@headlessui/react'
import React from 'react'
import Text from '../Text/Text'
import UserAvatar from './UserAvatar'

interface Props {
  message: Chat
}

const Message: React.FC<Props> = ({ message }) => {
  return (
    <Transition
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      appear
      show
    >
      <div className="mb-4 xtransition-opacity duration-500 flex text-white">
        <UserAvatar
          avatarUrl={message.avatarUrl}
          name={message.name}
          className="flex-shrink-0 mr-2"
        />
        <div className="mt-1">
          <Text as="span" preset="body.md" className="font-semibold mr-2">
            {message.name}
          </Text>
          <Text
            as="span"
            preset="custom"
            className="font-light tracking-tight text-gray-7 text-sm"
          >
            {message.message}
          </Text>
        </div>
      </div>
    </Transition>
  )
}

export default Message
