import { useEffect, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { CardModal, HeightTransitioner } from '@/components/common'
import LoginOptions from './LoginOptions'
import Signup from './Signup'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import useAuth from '@/services/hooks/useAuth'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import Image from '@/components/contentful/Image'
import { AngelFundingLogo } from '@/components/svg'
import VerifyAccountForm from './VerifyAccountForm'
import VerifyAccount from './VerifyAccount'

export const AuthModal: React.FC = () => {
  const [email, setEmail] = useState('')
  const { modalIsOpen, closeModal, modalView, setModalView, dismissable } =
    useAuth()
  const router = useRouter()
  const siteAssets = useSiteAssets()

  useEffect(() => {
    if (modalIsOpen && modalView) {
      SegmentHandler.track('Auth Started', {
        location: router.asPath,
        value: modalView,
      })
    }
  }, [modalIsOpen, modalView, router.asPath])

  const setIsOpen = useCallback(() => {
    // Don't have a good way to track resetPassword cancellation because
    // the modal stays open after a successful reset.
    if (modalView !== 'resetPassword') {
      SegmentHandler.track('Auth Cancelled', {
        location: router.asPath,
        value: modalView,
      })
    }

    closeModal()
  }, [modalView, router.asPath, closeModal])

  return (
    <CardModal
      dismissable={dismissable}
      isOpen={modalIsOpen}
      setIsOpen={setIsOpen}
      width="w-[360px]"
      zIndex="9999"
    >
      <HeightTransitioner>
        <div className="w-full px-8 pt-8 pb-6">
          <div className="mb-12">
            {siteAssets && siteAssets.logo && (
              <Image
                image={siteAssets.logo}
                width={160}
                className="w-40 mx-auto flex-initial"
              />
            )}
            {siteAssets && !siteAssets.logo && (
              <AngelFundingLogo className="w-40 mx-auto flex-initial" />
            )}
          </div>
          {modalView === 'eiVerifyAccount' && <VerifyAccount />}
          {modalView === 'verifyAccount' && (
            <VerifyAccountForm
              email={email}
              setEmail={setEmail}
              setView={setModalView}
            />
          )}
          {(modalView === 'register' || modalView === 'verifyEmail') && (
            <Signup
              email={email}
              setEmail={setEmail}
              view={modalView}
              setView={setModalView}
            />
          )}
          {(modalView === 'login' || modalView === 'resetPassword') && (
            <LoginOptions
              email={email}
              setEmail={setEmail}
              view={modalView}
              setView={setModalView}
            />
          )}
        </div>
      </HeightTransitioner>
    </CardModal>
  )
}

export default AuthModal
