import { useCallback, useEffect, useRef, useState } from 'react'
import { Channel, Socket } from 'phoenix'
import qs from 'qs'
import {
  Chat,
  ChatForm,
  InvestmentChat,
  ServerInvestmentChat,
} from '@/services/api/chatbot'
import { casingUtil, nameUtil } from '@/utils'
import useParty from '../useParty'
import useAuth from '../useAuth'
import { getPersistedAuth } from '@/components/context/AuthContext/utils'

enum SocketChannelTopic {
  channelStatus = 'channel_status',
  deleteMessage = 'delete_message',
  fundingInvestment = 'funding:investment',
  shout = 'shout',
  userBanned = 'user_banned',
  userCountUpdate = 'user_count_update',
}

interface SubscribeChat {
  chatCode: string
  setMessages?: React.Dispatch<React.SetStateAction<Chat[]>>
  setInvestmentMessages?: React.Dispatch<React.SetStateAction<InvestmentChat[]>>
}

export const useSubscribeChat = ({
  chatCode,
  setMessages,
  setInvestmentMessages,
}: SubscribeChat) => {
  const [activeChannel, setActiveChannel] = useState<Channel | null>()
  const [chatSocket, setChatSocket] = useState<Socket>()
  const [chatStatus, setChatStatus] = useState<'online' | 'offline'>('offline')
  const [connectionStatus, setConnectionStatus] = useState<
    'loading' | 'connecting' | 'connected' | 'timed out' | 'error'
  >('loading')
  const [error, setError] = useState()
  const [totalViewers, setTotalViewers] = useState(0)
  const chatCodeRef = useRef('')
  const isShoutInitRef = useRef(false)
  const didSendAuthRef = useRef(false)
  const { user } = useAuth()
  const { party, isLoading: isPartyLoading } = useParty()

  useEffect(() => {
    const { token } = getPersistedAuth()
    if (
      activeChannel &&
      !didSendAuthRef?.current &&
      token &&
      chatStatus === 'online'
    ) {
      const { tokenOverride } = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      activeChannel.push('auth', { token: tokenOverride || token })
      didSendAuthRef.current = true
    }
  }, [activeChannel, chatStatus])

  const connect = useCallback(() => {
    setConnectionStatus('connecting')

    const url = `${process.env.NEXT_PUBLIC_CHAT_SERVER}/socket`

    const socket = new Socket(url)
    socket.connect()

    setChatSocket(socket)

    const channel = socket.channel(`chat:${chatCode}`)

    channel
      .join()
      .receive('ok', (payload) => {
        setActiveChannel(channel)
        setConnectionStatus('connected')
        setTotalViewers(payload.total_viewers || 0)
        setChatStatus(payload.chat_status)
      })
      .receive('error', (error) => {
        setConnectionStatus('error')
        setError(error)
      })
      .receive('timeout', () => {
        setConnectionStatus('timed out')
      })
  }, [chatCode])

  // Cleanup
  useEffect(() => {
    return () => {
      activeChannel?.leave()
      chatSocket?.disconnect()
      setConnectionStatus('loading')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !chatCode ||
      (chatCode === chatCodeRef.current && activeChannel) ||
      connectionStatus !== 'loading'
    ) {
      return
    }

    chatCodeRef.current = chatCode
    connect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCode, activeChannel, connectionStatus])

  const updateList = useCallback(
    (message: Chat) => {
      if (!setMessages) return

      setMessages((currentMessages: Chat[]) => {
        const slice = currentMessages.slice(
          Math.max(currentMessages.length - 100, 0)
        )
        return [...slice, message]
      })
    },
    [setMessages]
  )

  const updateInvestmentList = useCallback(
    (messagePayload: ServerInvestmentChat) => {
      if (!setInvestmentMessages) return
      const message = casingUtil.snakeToCamel(messagePayload) as InvestmentChat

      setInvestmentMessages((currentMessages: InvestmentChat[]) => {
        const slice = currentMessages.slice(
          Math.max(currentMessages.length - 100, 0)
        )
        return [...slice, message]
      })
    },
    [setInvestmentMessages]
  )

  useEffect(() => {
    if (activeChannel?.state === 'joined' && !isShoutInitRef.current) {
      activeChannel?.on(SocketChannelTopic.shout, updateList)

      activeChannel?.on(SocketChannelTopic.userCountUpdate, ({ count }) => {
        setTotalViewers(count)
      })

      activeChannel?.on(
        SocketChannelTopic.fundingInvestment,
        updateInvestmentList
      )

      activeChannel?.on(SocketChannelTopic.deleteMessage, (message) => {
        if (setMessages) {
          const messageToDelete = casingUtil.snakeToCamel(message) as Chat
          setMessages((currentMessages: Chat[]) => {
            return [
              ...currentMessages.filter(
                (m) => m.publicId !== messageToDelete.publicId
              ),
            ]
          })
        }

        if (setInvestmentMessages) {
          setInvestmentMessages((currentMessages: InvestmentChat[]) => {
            return [...currentMessages.filter((m) => m.id !== message.id)]
          })
        }
      })

      activeChannel?.on(SocketChannelTopic.userBanned, ({ user_id }) => {
        if (!setMessages) return

        setMessages((currentMessages: Chat[]) => {
          return currentMessages.filter((m) => m.userId !== user_id)
        })
      })

      activeChannel?.on(SocketChannelTopic.channelStatus, ({ chat_status }) => {
        setChatStatus(chat_status)
      })

      isShoutInitRef.current = true
    }
  }, [
    activeChannel,
    isShoutInitRef,
    setMessages,
    setInvestmentMessages,
    updateList,
    updateInvestmentList,
  ])

  const sendMessage = useCallback(
    (text: string) => {
      if (activeChannel && text !== '' && user?.id && !isPartyLoading) {
        const name = nameUtil.getDisplayName(party, user)

        const myMessage: ChatForm = {
          avatarUrl: '',
          name,
          message: text,
          userId: user.id,
        }

        activeChannel.push(SocketChannelTopic.shout, myMessage)
      }
    },
    [activeChannel, user, party, isPartyLoading]
  )

  return {
    chatStatus,
    connectionStatus,
    error,
    sendMessage,
    totalViewers,
  }
}
