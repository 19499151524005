import React from 'react'
import ButtonBase from '../ButtonBase'
import { Plus, Minus } from '@/components/svg'
import { useAccordionContext } from './AccordionContext'

export interface Props {
  index: number
  children: React.ReactNode
  className?: string
}

const AccordionSummary: React.FC<Props> = ({ index, children, className }) => {
  const { expanded, setExpanded } = useAccordionContext()
  const isExpanded = expanded === index
  const ToggleIcon = isExpanded ? Minus : Plus

  return (
    <ButtonBase
      className={className}
      onClick={() => setExpanded(isExpanded ? -1 : index)}
    >
      <div className="flex items-center">
        <div className="text-left pr-4">{children}</div>
        <ToggleIcon className="w-4 h-4 sm:w-5 sm:h-5 shrink-0 ml-auto" />
      </div>
    </ButtonBase>
  )
}

export default AccordionSummary
