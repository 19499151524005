import React, { useCallback, useState } from 'react'
import { Button, Type } from '../common'
import useAuth from '@/services/hooks/useAuth'
import { resendVerificationEmail } from '@/services/api/ellisIslandClient/ellisIslandClient'
import { useRouter } from 'next/router'
import { Checkmark } from '../svg'
import { buildShortbreadUrl } from '@/utils'
import { getPersistedAuth } from '../context/AuthContext/utils'
import useSiteAssets from '@/services/hooks/useSiteAssets'

const VerifyAccount = () => {
  const { user, redirect } = useAuth()
  const [emailSent, setEmailSent] = useState(false)
  const router = useRouter()
  const { ellisIslandClientId } = useSiteAssets()

  const handleClick = useCallback(
    async (event: React.FormEvent) => {
      const { token } = getPersistedAuth()
      setEmailSent(true)
      const emailRedirectUrl = redirect
        ? buildShortbreadUrl(redirect)
        : buildShortbreadUrl(router.asPath)
      event.preventDefault()
      !!token &&
        resendVerificationEmail(token, emailRedirectUrl, ellisIslandClientId)
    },
    [redirect, router]
  )

  return (
    <div>
      <Type
        variant="title-md"
        as="h3"
        className="mb-5 -mt-6 text-center font-bold"
      >
        Verify your email address
      </Type>
      <Type variant="paragraph-md" as="p" className="text-center mb-5">
        We’ve sent a verification link to {user?.email}. Check your inbox and
        spam. Open the email and follow the instructions.
      </Type>

      <div className="relative pt-10">
        {emailSent && (
          <Type
            variant="paragraph-md"
            as="p"
            className="absolute top-0 left-0 right-0 font-bold italic text-gray"
          >
            <span className="flex items-center justify-center">
              <Checkmark className="w-5 h-5 mr-2" />
              <span>Email Sent</span>
            </span>
          </Type>
        )}

        <Type variant="paragraph-md" as="p" className="text-center">
          Didn’t get the verification email?
        </Type>

        <Button
          onClick={handleClick}
          variant="link"
          disabled={emailSent}
          className="w-full full flex items-center justify-center font-bold"
        >
          <span>Resend Verification Email</span>
        </Button>
      </div>
    </div>
  )
}

export default VerifyAccount
