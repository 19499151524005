import useHydration from '@/services/hooks/useHydration'
import PageSpinner from './PageSpinner'

const PageLoader = () => {
  const { isHydrated } = useHydration()

  if (!isHydrated) {
    return null
  }

  return (
    <>
      <div className="flex-1 h-[100vh]" />
      <PageSpinner active />
    </>
  )
}

export default PageLoader
