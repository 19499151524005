import axios from 'axios'
import { EllisIslandAuthServer } from './types'
import { resolveEllisIslandAuthDown } from './resolver'
export const baseURL =
  process.env.NEXT_PUBLIC_ELLIS_ISLAND_URL || 'https://auth.angelstudios.com'

const apiClient = axios.create({ baseURL })

// Write function that refreshes a token.
const refreshAuthToken = async (refreshToken: string, clientId: string) =>
  apiClient
    .post(`${baseURL}/oauth/token`, {
      client_id: clientId,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    })
    .then((res: { data: EllisIslandAuthServer }) =>
      resolveEllisIslandAuthDown(res.data)
    )

// The redirect is the url that the user will be redirected to after clicking
// the verification link in the email.
const resendVerificationEmail = async (
  acessToken: string,
  redirectUrl: string,
  clientId: string
) =>
  apiClient.post(
    `${baseURL}/resend-verification-email`,
    { return_to: redirectUrl, client_id: clientId },
    { headers: { Authorization: acessToken } }
  )

export { refreshAuthToken, resendVerificationEmail }
