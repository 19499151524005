import Link from 'next/link'
import React from 'react'
import Type from '../Type'
import { ChevronRight } from '@/components/svg'

interface Props {
  className?: string
  href: string
  text?: string
}

const BackLink: React.FC<Props> = ({ className, href, text = 'Back' }) => {
  return (
    <Link href={href} className={className}>
      <Type
        as="span"
        variant="caption-md"
        className="text-core-oxide flex items-center"
      >
        <ChevronRight className="w-4 rotate-180" /> {text}
      </Type>
    </Link>
  )
}

export default BackLink
