import React from 'react'
import AccordionContext, { Context } from './AccordionContext'
import AccordionSummary from './AccordionSummary'
import AccordionDetails from './AccordionDetails'

interface AccordionType extends Context {
  children: React.ReactNode
}

const Accordion = ({
  expanded,
  setExpanded,
  speed = 'normal',
  children,
}: AccordionType) => (
  <AccordionContext.Provider
    value={{
      expanded,
      setExpanded,
      speed,
    }}
  >
    {children}
  </AccordionContext.Provider>
)

Accordion.Summary = AccordionSummary

Accordion.Details = AccordionDetails

export default Accordion
