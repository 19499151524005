import React, { useMemo } from 'react'
import Head from 'next/head'
import { Asset } from 'contentful'
import { ISeoFields } from 'src/types/generated/contentful'
import useSiteAssets from '@/services/hooks/useSiteAssets/useSiteAssets'
import { whiteLabelUtil } from '@/services/api/siteAssets'

export interface Props extends Partial<Omit<ISeoFields, 'image'>> {
  image?: string | Asset
  fullPath: string
}

const SEO: React.FC<Props> = ({
  description = 'Angel Funding - Invest in stories that amplify light',
  image = 'https://images.angelstudios.com/image/upload/v1699022164/angel-funding/angel_funding_meta.jpg',
  name = 'Angel Funding',
  title = 'Angel Funding - Invest in stories that amplify light',
  fullPath,
}) => {
  const { domain } = useSiteAssets()
  const imageAlt = typeof image === 'object' ? image?.fields?.description : ''
  const imageSrc = useMemo(() => {
    if (typeof image === 'string') {
      return image
    }

    const {
      url,
      details: { image: { width = 1, height = 1 } = {} },
    } = image?.fields?.file || { details: { image: {} } }

    // try to force optimal size if image is large enough
    if (width > 1200 || height > 630) {
      return `https:${url}?w=1200&h=630&fit=scale`
    }

    return `https:${url}`
  }, [image])

  const [path] = fullPath.split('?')
  const canonicalUrl = whiteLabelUtil.resolveCanonicalUrl(path, domain)

  return (
    <Head>
      <title key="title">{title}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta key="og:site_name" property="og:site_name" content={name} />
      <meta key="og:locale" property="og:locale" content="en_US" />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:image" property="og:image" content={imageSrc} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta key="og:url" property="og:url" content={canonicalUrl} />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={description}
      />
      <meta key="twitter:image" name="twitter:image" content={imageSrc} />
      <meta
        key="twitter:image:alt"
        name="twitter:image:alt"
        content={imageAlt}
      />
      <meta key="fb:app_id" property="fb:app_id" content="496653524221423" />
      <link key="favicon" rel="icon" href="/favicon.ico" />
    </Head>
  )
}

export default SEO
