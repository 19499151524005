import { SWRConfiguration } from 'swr'
import useApi from '../useApiV2'
import {
  CampaignAssets,
  getCampaignAssets,
} from '@/services/api/campaignAssets'

const useCampaignAssets = (slug: string, config?: SWRConfiguration) => {
  const { data, ...rest } = useApi<CampaignAssets>({
    key: `campaign-assets/${slug}`,
    request: () => getCampaignAssets(slug),
    config,
  })

  return { campaignAssets: data, ...rest }
}

export default useCampaignAssets
