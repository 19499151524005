import { ChevronDown } from '@/components/svg'
import { Campaign } from '@/services/api/campaign'
import { InvestmentChat } from '@/services/api/chatbot'
import useInvestmentChatbot from '@/services/hooks/useInvestmentChatbot'
import { useSubscribeChat } from '@/services/hooks/useSubscribeChat'
import { report, resolveErrorMessage, scrollUtil } from '@/utils'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ButtonBase from '../ButtonBase'
import Message from './Message'
import Type from '../Type'

interface Props {
  campaign: Campaign
  className?: string
}

const ChatBot: React.FC<Props> = ({ campaign, className }) => {
  const [isFollowing, setFollowing] = useState(true)
  const [messages, setMessages] = useState<InvestmentChat[]>([])
  const scrollPositionRef = useRef<number>(0)
  const scrollableContainer = useRef<HTMLDivElement>(null)
  const { isError, data } = useInvestmentChatbot(campaign.slug)

  const { error: subscribeError } = useSubscribeChat({
    chatCode: campaign.channelId,
    setInvestmentMessages: setMessages,
  })

  useEffect(() => {
    if (!data) return

    setMessages(data)
  }, [data])

  useEffect(() => {
    const element = scrollableContainer.current
    if (element && isFollowing) {
      scrollPositionRef.current = element.scrollHeight
      scrollUtil.scrollToBottomOfElement(element)
    }
  }, [messages, isFollowing])

  const onScroll = () => {
    const chatContainer = scrollableContainer.current

    if (!chatContainer) {
      return
    }

    if (
      chatContainer.scrollTop + chatContainer.clientHeight >=
      scrollPositionRef.current
    ) {
      setFollowing(true)
    } else {
      setFollowing(false)
    }
  }

  const stopFollowing = useCallback(() => {
    setFollowing(false)
  }, [setFollowing])

  const startFollowing = useCallback(() => {
    setFollowing(true)
  }, [setFollowing])

  if (isError) {
    report.error(resolveErrorMessage(isError as Error))
    return null
  }
  // report it but still show recent investments, i.e. continue rendering
  if (subscribeError) {
    report.error(resolveErrorMessage(subscribeError))
  }

  return (
    <div className={classNames('relative overflow-hidden', className)}>
      <Type
        as="h3"
        variant="title-xs"
        className="bg-white absolute top-0 w-full border border-core-gray-200 font-bold p-4 rounded-t-2xl text-core-gray-800 shadow-light z-10"
      >
        Investment Updates
      </Type>
      <div
        className="h-72 overflow-y-scroll border border-core-gray-200 bg-white px-4 pb-2 pt-16 rounded-2xl scrollbar"
        ref={scrollableContainer}
        onScroll={onScroll}
        onClick={stopFollowing}
        onTouchMove={stopFollowing}
        onMouseDown={stopFollowing}
      >
        {messages.map((message) => (
          <Message message={message} key={message.id} />
        ))}
      </div>

      <ButtonBase
        className={classNames(
          'bg-gray-1 mx-auto drop-shadow-xl h-[38px] w-[38px] p-3 -mt-4 rounded-full flex items-center justify-center z-10 transition-opacity delay-100',
          {
            'opacity-0': isFollowing,
          }
        )}
        onClick={startFollowing}
      >
        <ChevronDown className={'stroke-white text-white'} />
      </ButtonBase>
    </div>
  )
}

export default ChatBot
