import client from '../thinMintClient'
import { resolveIVADown, resolveIVAUp } from './resolver'
import { ServerIVA, IVAForm } from './types'
import { User } from '../user'

export const getUserIVAs = () =>
  client.get<ServerIVA[]>(`iva/`).then((res) => res.data.map(resolveIVADown))

export const getInvestmentIVAs = (investmentId: string) =>
  client
    .get<ServerIVA[]>(`investments/${investmentId}/owned-open-ivas/`)
    .then((res) => res.data.map(resolveIVADown))

export const getCampaignIVAs = (slug: string) =>
  client
    .get<ServerIVA[]>(`iva/?campaign_slug=${slug}`)
    .then((res) => res.data.map(resolveIVADown))

export const getCampaignIVAsWithAuthToken = (slug: string, token: string) =>
  client
    .get<ServerIVA[]>(`iva/?campaign_slug=${slug}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data.map(resolveIVADown))

export const updatePartyIVA = (partyId: number, ivaId: number, form: IVAForm) =>
  client.patch<ServerIVA>(
    `parties/${partyId}/iva/${ivaId}/`,
    resolveIVAUp(form)
  )

export const updateEntityIVA = (
  entityId: number,
  ivaId: number,
  form: IVAForm
) =>
  client.patch<ServerIVA>(
    `entities/${entityId}/iva/${ivaId}/`,
    resolveIVAUp(form)
  )

export const keyGen = {
  getUserIVAs: (user?: User | null) => (user ? `iva/${user.id}` : false),
  getInvestmentIVAs: (investmentId: string) => `iva/${investmentId}`,
  getCampaignIVAs: (slug: string) => `iva/${slug}`,
}
