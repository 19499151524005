import { ISiteAssetsFields } from 'src/types/generated/contentful'
import { Campaign } from '@/services/api/campaign/types'
import { Pledge } from '@/services/api/pledge/types'
import { InvestorUpdate } from '@/services/api/investorUpdate/types'
import config from '../../../../whiteLabel-config.json'

export type Domain = keyof typeof extraConfig

export const isWhiteLabel = (domain: Domain) =>
  !!config[domain as keyof typeof config]

export const getDomainFromSlug = (slug: string): Domain => {
  let domain: Domain = 'invest.angel.com'

  const entries = Object.entries(config)
  for (let i = 0; i < entries.length; i += 1) {
    const [entryDomain, slugs] = entries[i]
    if (slugs.includes(slug)) {
      domain = entryDomain as Domain
      break
    }
  }

  return domain
}

const davidHostnames = [
  'david-reg-a.dev.angel.com',
  'david-reg-a.stg.angel.com',
  'thedavidmovie.com',
  'www.thedavidmovie.com',
]

const regAHostnames = [
  'angel-reg-a.com',
  'www.angel-reg-a.com',
  'dev.angel-reg-a.com',
  'stg.angel-reg-a.com',
]

export const getDomainFromHostname = (hostname: string): Domain => {
  if (hostname.startsWith('david.') || davidHostnames.includes(hostname)) {
    return 'thedavidmovie.com'
  }

  if (hostname.startsWith('angel-reg-a.') || regAHostnames.includes(hostname)) {
    return 'angel-reg-a.com'
  }

  return 'invest.angel.com'
}

export const isAllowed = (
  obj: Campaign | Pledge | InvestorUpdate,
  domain: Domain
) => {
  const whiteLabelCampaigns = config[domain as keyof typeof config] || null

  let slug = ''
  if ('slug' in obj) slug = obj.slug
  if ('campaign' in obj) slug = obj.campaign
  if ('campaignSlug' in obj) slug = obj.campaignSlug

  return whiteLabelCampaigns
    ? whiteLabelCampaigns.includes(slug)
    : !Object.values(config).flat().includes(slug)
}

const regexMap = {
  'invest.angel.com': /^\/home/,
  'thedavidmovie.com': /^\/david/,
  'angel-reg-a.com': /^\/angel-reg-a/,
}

// Removes the path rewrites from ./middleware.ts for Vercel platforms config
export const resolveCanonicalUrl = (path: string, domain: Domain) => {
  const regex = regexMap[domain]
  const cleanPath = path.replace(regex, '')

  return `https://${domain}${cleanPath}`
}

export const whiteLabelUtil = {
  isWhiteLabel,
  getDomainFromSlug,
  getDomainFromHostname,
  isAllowed,
  resolveCanonicalUrl,
}

const defaultConfig = {
  ellisIslandClientId: 'angel_funding',
  zendeskKey: '361a1ab1-1121-48d7-a18c-c0ce46561cd0',
  cognitoFormsKey: '',
  cognitoFormsId: '',
  timeRemainingDescriptor: 'Left',
  fundingCampaignDisclaimer: '',
  footerDisclaimer: `This website is owned and operated by VAS Portal, LLC doing business as Angel Funding. All funding portal activities are conducted by VAS Portal, LLC dba Angel Funding. Any Reg A or Reg D offering listed on this website are managed by the broker dealer listed on the offering details.`,
  title: 'Angel Funding', // TODO: title is temporarily hard-coded as we determine how best to fetch assets for multiple sites
  usePriorRaisesLayout: false,
  priorRaisesDisclaimer: '',
  campaignSummary: '',
  priorRaisesGoalAmount: 0,
  priorRaisesTitle: '',
  captureChatbotConsent: false,
  isChatbotAllowed: false,
  showSecondaryCta: false,
  hideTimeRemaining: false,
  pifUrl: '',
  canExpressInterest: true,
  isExecutingSharePriceChange: false,
  useAltProgressColor: false,
} as const

export const extraConfig = {
  'invest.angel.com': { ...defaultConfig },
  'thedavidmovie.com': {
    ellisIslandClientId: 'david_reg_a',
    zendeskKey: '',
    cognitoFormsKey: 'E6D1XHWl6EK3GTVlg-eitw',
    cognitoFormsId: '4',
    timeRemainingDescriptor: 'Left*',
    fundingCampaignDisclaimer: `*$60,982,236 represents the current overall funding target for this project. DAVID is currently raising funding toward that goal in a Regulation A investment offering. DAVID is offering investments up to a maximum of $37.5 million in the current offering. More information about the current offering is available in the offering tab below.`,
    footerDisclaimer: `DISCLAIMER:

    THESE OFFERING MATERIALS MAY CONTAIN FORWARD-LOOKING STATEMENTS AND INFORMATION RELATING TO, AMONG OTHER THINGS, THE COMPANY, ITS BUSINESS PLAN AND STRATEGY, AND ITS INDUSTRY. THESE FORWARD LOOKING STATEMENTS ARE BASED ON THE BELIEFS OF, ASSUMPTIONS MADE BY, AND INFORMATION CURRENTLY AVAILABLE TO THE COMPANY’S MANAGEMENT.
    
    WHEN USED IN THE OFFERING MATERIALS, THE WORDS “ESTIMATE,” “PROJECT,” “BELIEVE,” “ANTICIPATE,” “INTEND,” “EXPECT” AND SIMILAR EXPRESSIONS ARE INTENDED TO IDENTIFY FORWARD-LOOKING STATEMENTS. THESE STATEMENTS REFLECT MANAGEMENT’S CURRENT VIEWS WITH RESPECT TO FUTURE EVENTS AND ARE SUBJECT TO RISKS AND UNCERTAINTIES THAT COULD CAUSE THE COMPANY’S ACTUAL RESULTS TO DIFFER MATERIALLY FROM THOSE CONTAINED IN THE FORWARD-LOOKING STATEMENTS. INVESTORS ARE CAUTIONED NOT TO PLACE UNDUE RELIANCE ON THESE FORWARD-LOOKING STATEMENTS, WHICH SPEAK ONLY AS OF THE DATE ON WHICH THEY ARE MADE. THE COMPANY DOES NOT UNDERTAKE ANY OBLIGATION TO REVISE OR UPDATE THESE FORWARD-LOOKING STATEMENTS TO REFLECT EVENTS OR CIRCUMSTANCES AFTER SUCH DATE OR TO REFLECT THE OCCURRENCE OF UNANTICIPATED EVENTS.
    
    PLEASE NOTE INVESTORS IN THIS OFFERING WILL BE CLIENTS OF THE ISSUER AND NOT DALMORE GROUP, LLC (“DALMORE”), A REGISTERED BROKER-DEALER AND MEMBER FINRA/SIPC. DALMORE’S ROLE IN THE TRANSACTION IS TO FACILITATE BACK OFFICE AND REGULATORY FUNCTIONS RELATED TO THE REGULATION A TRANSACTION, AND ACTS ONLY AS THE BROKER/DEALER OF RECORD FOR THE OFFERING LISTED. DALMORE IS NOT PROVIDING INVESTMENT ADVICE OR RECOMMENDATIONS, OR LEGAL OR TAX ADVICE. 
    
    AN OFFERING STATEMENT REGARDING THIS OFFERING HAS BEEN FILED WITH THE SEC. THE SEC HAS QUALIFIED THAT OFFERING STATEMENT, WHICH ONLY MEANS THAT THE COMPANY MAY MAKE SALES OF THE SECURITIES DESCRIBED BY THE OFFERING STATEMENT. IT DOES NOT MEAN THAT THE SEC HAS APPROVED, PASSED UPON THE MERITS OR PASSED UPON THE ACCURACY OR COMPLETENESS OF THE INFORMATION IN THE OFFERING STATEMENT. THE OFFERING CIRCULAR THAT IS PART OF THAT OFFERING STATEMENT IS AT: https://www.sec.gov/Archives/edgar/data/1893768/000121465922013223/partiiandiii.htm

    Slingshot USA, LLC is the issuer (the "Issuer") of the offering listed on the website with Dalmore Group, LLC as the Broker Dealer of Record. This technology platform is provided by Angel Funding for the Issuer.`,
    title: 'The David Movie',
    usePriorRaisesLayout: true,
    priorRaisesDisclaimer: `DAVID is the offering investment in this Regulation A offering. By investing in this Regulation A offering you purchase a membership interest in the Slingshot USA LLC (the entity that owns DAVID) and become a member of the LLC and subject to its operating agreement. The minimum investment amount for this Reg. A round of funding is $99. For more detailed information about the Regulation A Offer please visit the Offering tab which provides the official investment documentation including the Offering Circular for this offering. Please note that investing in this Regulation A offering involves a high degree of risk including the possibility of the total loss of your investment, you should consult with your advisors prior to making an investment decision.`,
    campaignSummary: `DAVID will be a musical animated feature film that combines powerful biblical authenticity, captivating music, storytelling, and production quality to stand alongside the best animation movies of all time.

Planned for a 2025 global cinema release, our prayer and passion is for this incredible story *of what God did through one faithful shepherd boy* to be one of the biggest cinema event and one of the most enduring, influential movies for generations to come.

*“David’s story has resonated with people from both within and outside the faith community for millennia and has proven its ability to reach the broadest possible audience. Our vision is to make a movie and tell a story that will delight and inspire the broadest possible audience while staying authentic to the Bible’s account of how one man’s relationship with God liberated a nation and changed the course of history.”*

Phil Cunningham - Director`,
    priorRaisesGoalAmount: 6098223600,
    priorRaisesTitle: 'Overall project funding goal',
    captureChatbotConsent: true,
    isChatbotAllowed: true,
    showSecondaryCta: false,
    hideTimeRemaining: true,
    pifUrl: 'https://shop.angel.com/products/pay-it-forward-david%E2%84%A2',
    canExpressInterest: false,
    isExecutingSharePriceChange: false,
    useAltProgressColor: true,
  },
  'angel-reg-a.com': { ...defaultConfig, ellisIslandClientId: 'angel_reg_a' },
} as const

export const resolveSiteAssets = (
  assets: ISiteAssetsFields,
  domain: Domain = 'invest.angel.com'
): SiteAssets => {
  return {
    ...assets,
    ...extraConfig[domain],
    domain,
    isWhiteLabel: whiteLabelUtil.isWhiteLabel(domain),
  }
}

export interface SiteAssets extends ISiteAssetsFields {
  domain: Domain
  isWhiteLabel: boolean
  zendeskKey: string
  cognitoFormsKey: string
  cognitoFormsId: string
  timeRemainingDescriptor: 'Left' | 'Left*'
  fundingCampaignDisclaimer: string
  footerDisclaimer: string
  usePriorRaisesLayout: boolean
  priorRaisesDisclaimer: string
  campaignSummary: string
  priorRaisesGoalAmount: number
  priorRaisesTitle: string
  captureChatbotConsent: boolean
  isChatbotAllowed: boolean
  showSecondaryCta: boolean
  hideTimeRemaining: boolean
  pifUrl: string
  canExpressInterest: boolean
  isExecutingSharePriceChange: boolean
  ellisIslandClientId: string
  useAltProgressColor: boolean
}
