import { refreshAuthToken } from '@/services/api/ellisIslandClient'
import { useCallback, useEffect, useRef } from 'react'
import { getPersistedAuth, setPersistedAuth } from './utils'
import { jwtDecode } from 'jwt-decode'
import { report } from '@/utils'
import { NotifiableError } from '@bugsnag/js'
import useSiteAssets from '@/services/hooks/useSiteAssets'

const useAuthTokenRefresher = ({
  enabled,
  timeout = 5000,
}: {
  enabled: boolean
  timeout?: number
}) => {
  const interval = useRef<ReturnType<typeof setInterval>>()
  const { ellisIslandClientId } = useSiteAssets()

  const needsRefresh = useCallback(() => {
    const { token, refreshToken } = getPersistedAuth()

    if (!refreshToken) return false

    const decodedToken = jwtDecode(token)
    const expiration = decodedToken.exp as number
    const tenMinutesFromNow = Date.now() / 1000 + 600
    // Forever tokens should be replaced. If the token
    // is more than one hour until expiration, it is a forever token
    const oneHourFromNow = Date.now() / 1000 + 3600

    return expiration < tenMinutesFromNow || expiration > oneHourFromNow
  }, [])

  const handleRefresh = useCallback(async () => {
    const auth = getPersistedAuth()
    const { accessToken, refreshToken } = await refreshAuthToken(
      auth.refreshToken,
      ellisIslandClientId
    )
    const decodedAuthToken = jwtDecode(accessToken)
    setPersistedAuth(decodedAuthToken.sub as string, accessToken, refreshToken)
  }, [])

  useEffect(() => {
    if (!enabled) {
      if (interval.current) clearInterval(interval.current)
      return
    }

    const onInterval = async () => {
      try {
        if (needsRefresh()) await handleRefresh()
      } catch (err) {
        const auth = getPersistedAuth()
        const error: NotifiableError = new Error(
          'Error refreshing auth token',
          { cause: err }
        )
        report.error(error, { token: auth?.refreshToken })
      }
    }

    // Run first time immediately, then on interval
    onInterval()
    interval.current = setInterval(onInterval, timeout)

    return () => {
      if (interval.current) clearInterval(interval.current)
    }
  }, [needsRefresh, handleRefresh, enabled, timeout])
}

export default useAuthTokenRefresher
