import client from '../thinMintClient'
import { InvestmentForm, ServerInvestment, UserPerk } from './types'
import {
  resolveDefaultInvestmentForm,
  resolveInvestmentDown,
  resolveInvestmentUp,
} from './resolver'
import { Party } from '../party'

export const baseEndpoint = '/investments'

export const getInvestments = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: { results: ServerInvestment[] } }) =>
      res.data.results.map(resolveInvestmentDown)
    )

export const getInvestmentsWithAuthToken = (token: string) =>
  client
    .get(`${baseEndpoint}/`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res: { data: { results: ServerInvestment[] } }) =>
      res.data.results.map(resolveInvestmentDown)
    )

export const createInvestment = (form: InvestmentForm) =>
  client
    .post(`${baseEndpoint}/`, resolveInvestmentUp(form))
    .then((res: { data: ServerInvestment }) => resolveInvestmentDown(res.data))

export const createDefaultInvestment = (
  party: Party,
  campaignSlug: string,
  token?: string
) =>
  client
    .post(
      `${baseEndpoint}/`,
      resolveInvestmentUp(resolveDefaultInvestmentForm(party, campaignSlug)),
      token
        ? {
            headers: {
              Authorization: token,
            },
          }
        : {}
    )
    .then((res: { data: ServerInvestment }) => resolveInvestmentDown(res.data))

export const updateInvestment = (investmentId: string, form: InvestmentForm) =>
  client
    .put(`${baseEndpoint}/${investmentId}/`, resolveInvestmentUp(form))
    .then((res: { data: ServerInvestment }) => resolveInvestmentDown(res.data))

export const getUserPerks = () =>
  client.get(`/user-perks/`).then((res: { data: { results: UserPerk[] } }) => {
    return res.data.results
  })

export const cancelInvestment = (investmentId: string) =>
  client
    .post(`${baseEndpoint}/${investmentId}/cancel_investment/`, {})
    .then((res: { data: { id: string } }) => res.data)

export const reconfirmInvestment = (investmentId: string) =>
  client
    .post(`${baseEndpoint}/${investmentId}/reconfirm/`, {})
    .then((res: { data: ServerInvestment }) => resolveInvestmentDown(res.data))
