import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { NotifiableError } from '@bugsnag/js'
import { googleLogin } from '@/services/api/auth'
import { Button } from '@/components/common'
import useAuth from '@/services/hooks/useAuth'
import { resolveErrorMessage, report } from '@/utils'
import { Google } from '@/components/svg'
import { loadGoogleScript, initCodeClient, getReqState } from './utils'

export interface Props {
  setError: (errMessage: string) => void
  className?: string
}

const GoogleAuthButton: React.FC<Props> = ({ setError, className }) => {
  const [initError, setInitError] = useState('')
  const [loading, setLoading] = useState(false)
  const { persistUser, closeModal, redirect } = useAuth()
  const reqState = useMemo(getReqState, [])

  const onSuccess = useCallback(
    async ({ code, error }) => {
      if (error) {
        setError(error)
        return
      }

      setLoading(true)

      try {
        const res = await googleLogin(reqState, code)
        persistUser(res.uuid, res.authorization, null, redirect)
        closeModal()
      } catch (err) {
        report.log(err as NotifiableError)
        setError(resolveErrorMessage(err as Error))
      } finally {
        setLoading(false)
      }
    },
    [persistUser, closeModal, redirect, setError, reqState]
  )

  const initialize = useCallback(async () => {
    setLoading(true)
    try {
      await loadGoogleScript()
    } catch (err) {
      setInitError(resolveErrorMessage(err as Error))
    } finally {
      setLoading(false)
    }
  }, [setInitError])

  const onClick = useCallback(() => {
    if (initError) {
      setError(initError)
    } else {
      setError('')

      try {
        const client = initCodeClient(reqState, onSuccess)
        client.requestCode()
      } catch (err) {
        report.log(err as NotifiableError)
        setError(resolveErrorMessage(err as Error))
      }
    }
  }, [setError, initError, reqState, onSuccess])

  useEffect(() => {
    initialize()
  }, [initialize])

  return (
    <div className={className}>
      <Button
        size="sm"
        color="gray"
        className="w-full flex flex-row items-center justify-center w-full relative"
        onClick={onClick}
        disabled={loading}
      >
        <Google className="text-sm mr-2" />
        <span>Login with Google</span>
      </Button>
    </div>
  )
}

export default GoogleAuthButton
