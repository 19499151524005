import React from 'react'
import { ButtonBase, Text } from '@/components/common'
import ResetPasswordForm from './ResetPasswordForm'
import LoginForm from './LoginForm'

const LoginOptions = ({ email, setEmail, view, setView }) => {
  return (
    <div className="flex flex-col items-center justify-between h-full w-full">
      {view === 'resetPassword' ? (
        <ResetPasswordForm
          email={email}
          setEmail={setEmail}
          view={view}
          setView={setView}
        />
      ) : (
        <LoginForm
          email={email}
          setEmail={setEmail}
          view={view}
          setView={setView}
        />
      )}
      <div className="flex flex-col w-full items-center mt-6">
        <Text as="h4" preset="heading.sm">
          Not a member?
        </Text>
        <ButtonBase
          className="text-core-oxide text-sm"
          onClick={() => setView('register')}
        >
          Sign Up
        </ButtonBase>
      </div>
    </div>
  )
}

export default LoginOptions
