import Type from '@/components/common/Type/Type'
import { Fire } from '@/components/svg'
import { Campaign } from '@/services/api/campaign'

interface Props {
  campaign: Campaign
  className?: string
  showHeading?: boolean
}

const SellingFast: React.FC<Props> = ({
  campaign,
  className,
  showHeading = true,
}) => {
  if (!campaign.isSellingFast) {
    return null
  }

  return (
    <div className={className}>
      {showHeading && (
        <Type
          as="div"
          variant="caption-md"
          className="font-bold flex-row flex text-core-gray-900"
        >
          <Fire color="#F45A3B" size={18} className="mr-1" />
          Selling Fast
        </Type>
      )}
      <Type as="div" variant="caption-sm">
        †Estimated time left until the offering is full based on current volume
        of investments.
      </Type>
    </div>
  )
}

export default SellingFast
