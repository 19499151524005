import React from 'react'
import '../styles/globals.css'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, {
  BugsnagErrorBoundary as BugsnagErrorBoundaryComponent,
} from '@bugsnag/plugin-react'
import * as FullStory from '@fullstory/browser'
import Head from 'next/head'
import { SWRConfig, SWRConfiguration } from 'swr'
import NotificationsProvider from '@/components/context/NotificationsContext/NotificationsProvider'
import { SiteAssetsProvider } from '@/components/context/SiteAssetsContext'
import Analytics from '@/components/Analytics'
import AuthModal from '@/components/auth/AuthModal'
import Footer from '@/components/pages/Footer'
import Header from '@/components/Header/Header'
import OptimizelyProvider from '@/components/OptimizelyProvider'
import SEO from '@/components/pages/SEO'
import Zendesk from '@/components/Zendesk'
import AuthProvider from '@/components/context/AuthContext/AuthProvider'
import Identify from '@/components/auth/Identify'
import BellNotificationSignal from '@/components/notifications/BellNotifications/BellNotificationSignal'
import ErrorBoundary from '@/components/ErrorBoundary'
import app from '../package.json'
import { ISeoFields } from 'src/types/generated/contentful'
import { SiteAssets, Domain } from '@/services/api/siteAssets'
import { NextRouter } from 'next/router'
import { Campaign } from '@/services/api/campaign'
import { PageTransitioner } from '@/components/common/PageLoader'

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG as string,
  appVersion: app.version,
  enabledReleaseStages: ['production', 'staging'],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_ENV_NAME,
})

const BugsnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(
  React
) as BugsnagErrorBoundaryComponent

if (typeof window !== 'undefined') {
  FullStory.init({
    orgId: process.env.NEXT_PUBLIC_FULLSTORY as string,
    devMode: process.env.NEXT_PUBLIC_ENV_NAME !== 'production',
  })
}

interface Props {
  Component: () => JSX.Element
  pageProps: {
    seo: ISeoFields
    siteAssets: SiteAssets
    domain: Domain
    campaign?: Campaign
    fallback?: SWRConfiguration['fallback']
  }
  router: NextRouter
}

const App: React.FC<Props> = ({
  Component,
  pageProps,
  router: serverRouter,
}) => {
  return (
    <BugsnagErrorBoundary>
      <ErrorBoundary>
        <SWRConfig
          value={
            pageProps?.fallback ? { fallback: pageProps.fallback } : undefined
          }
        >
          <NotificationsProvider>
            <OptimizelyProvider>
              <SiteAssetsProvider
                assets={pageProps?.siteAssets}
                domain={pageProps?.domain}
              >
                <AuthProvider>
                  <Head>
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1"
                    />
                    {process.env.NEXT_PUBLIC_ENV_NAME !== 'production' && (
                      <meta name="robots" content="noindex,nofollow" />
                    )}
                  </Head>
                  <PageTransitioner />
                  <SEO {...pageProps?.seo} fullPath={serverRouter.asPath} />
                  <Analytics {...pageProps} />
                  <Zendesk />
                  <Identify />
                  <BellNotificationSignal />
                  <Header
                    transparentBackground={serverRouter.route === '/home'}
                  />

                  <div className="flex-1 font-whitney text-core-gray-950 bg-core-gray-100">
                    <AuthModal />
                    <Component {...pageProps} key={serverRouter.route} />
                  </div>
                  <Footer />
                </AuthProvider>
              </SiteAssetsProvider>
            </OptimizelyProvider>
          </NotificationsProvider>
        </SWRConfig>
      </ErrorBoundary>
    </BugsnagErrorBoundary>
  )
}

export default App
