import React, { useRef, useEffect, useCallback } from 'react'
import classNames from 'classnames'
import { useFormikContext } from 'formik'
import ButtonBase from '@/components/common/ButtonBase'
import Button from '@/components/common/Button'
import { CloseAlt } from '@/components/svg'

interface Props {
  name: string
  value: File[]
  accept?: string
  className?: string
}

const FileSelector: React.FC<Props> = ({ name, value, accept, className }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const input = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (value?.length) {
      // We're not using the regular Formik onChange/Focus/Blur handlers because
      // they don't work well with the file inputs. So we need to set field touched
      // if we have a value so we can display errors
      setFieldTouched(name, true)
    }
  }, [name, value, setFieldTouched])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        setFieldValue(name, [file])
      }
    },
    [name, setFieldValue]
  )

  return (
    <div
      className={classNames(
        {
          'relative flex items-center': true,
        },
        className
      )}
    >
      <Button
        onClick={() => input.current?.click()}
        size="xs"
        className="relative z-20"
        type="button"
      >
        Select File
      </Button>

      <div
        className={classNames({
          'flex-1 px-2 text-sm truncate': true,
          'text-gray': !value,
          'text-black': !!value,
        })}
      >
        {value?.[0]?.name || 'No file chosen'}
      </div>

      {value?.length > 0 && (
        <ButtonBase
          onClick={() => setFieldValue(name, [])}
          className="relative z-20 ml-1 p-2"
          aria-label="Remove File"
          type="button"
        >
          <CloseAlt className="w-2 h-2 text-gray-4" />
        </ButtonBase>
      )}

      <input
        ref={input}
        id={name}
        name={name}
        type="file"
        accept={accept}
        onChange={handleChange}
        className="absolute inset-0 z-10 opacity-0"
      />
    </div>
  )
}

export default FileSelector
