import { casingUtil } from '@/utils'
import { Investment, InvestmentForm, ServerInvestment } from './types'
import {
  resolveBirthday,
  resolveBirthdayPayload,
  resolvePhoneNumber,
} from '../resolvers/resolvers'
import { Party } from '../party'

export const resolveInvestmentDown = ({
  account,
  has_ssn,
  birthday,
  phone_number,
  ...rest
}: ServerInvestment) => {
  return {
    ...casingUtil.snakeToCamel(rest),
    accountId: account,
    hasSsn: has_ssn,
    birthday: resolveBirthday(birthday),
    phoneNumber: resolvePhoneNumber(phone_number),
  } as Investment
}

export const resolveInvestmentUp = ({
  accountId,
  amount,
  birthday,
  ...rest
}: InvestmentForm) => {
  return {
    ...casingUtil.camelToSnake(rest),
    account: accountId,
    amount: typeof amount === 'string' ? parseFloat(amount) : amount,
    birthday: resolveBirthdayPayload(birthday),
  }
}

export const resolveDefaultInvestmentForm = (
  party: Party,
  campaignSlug: string
) => ({
  accountId: party.individualAccount,
  firstName: party.firstName,
  lastName: party.lastName,
  email: party.email,
  phoneNumber: party.phoneNumber,
  birthday: party.birthday,
  address1: party.address1,
  address2: party.address2,
  city: party.city,
  state: party.state,
  country: party.country,
  postalCode: party.postalCode,
  campaign: campaignSlug,
  amount: 0,
  shares: 0,
})
