import React, { useRef } from 'react'
import useOnScreen from '@/services/hooks/useOnScreen'
import classNames from 'classnames'
import ABTester, { ABTestProps } from '@/components/ABTester'
import useHydration from '@/services/hooks/useHydration'

const variants = {
  fadeIn: {
    easing: 'ease-out',
    from: 'opacity-0',
    to: 'opacity-100',
  },
  slideUp: {
    easing: 'ease-out',
    from: 'opacity-0 translate-y-full',
    to: 'opacity-100 translate-y-0',
  },
  slideOver: {
    easing: 'ease-out',
    from: 'opacity-0 translate-x-[-10%]',
    to: 'opacity-100 translate-x-0',
  },
}

const speeds = {
  slow: 'duration-[2s]',
  medium: 'duration-1000',
  fast: 'duration-500',
  custom: '', // when passing custom should supply own duration className in className prop
}

interface Variant {
  easing: string
  from: string
  to: string
}

export interface Props {
  as?: React.ElementType
  variant: keyof typeof variants | Variant
  speed?: keyof typeof speeds
  animateOnce?: boolean
  className?: string
  children: React.ReactNode
}

export const AnimateIn: React.FC<Props> = ({
  as: Tag = 'div',
  variant,
  speed = 'medium',
  animateOnce = true,
  children,
  className,
}) => {
  const ref = useRef(null)
  const entered = useOnScreen(ref, { observeOnce: animateOnce })

  const { easing, from, to } =
    typeof variant === 'string' ? variants[variant] : variant
  const duration = speeds[speed]

  return (
    <Tag
      ref={ref}
      className={classNames(
        'transition transition-all transform',
        easing,
        duration,
        className,
        { [from]: !entered, [to]: entered }
      )}
    >
      {children}
    </Tag>
  )
}

const ABAnimateIn: React.FC<Props> = (props) => {
  const { isHydrated } = useHydration()

  if (!isHydrated) return null

  return (
    <ABTester name="campaign-animations" key={`${isHydrated}`}>
      {({ decision }: ABTestProps) => {
        if (decision.variationKey === 'on') return <AnimateIn {...props} />
        const { as: Tag = 'div', className, children } = props
        return <Tag className={className}>{children}</Tag>
      }}
    </ABTester>
  )
}

export default ABAnimateIn
