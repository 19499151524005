import React, { useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import { resetPasswordEmail } from '@/services/api/auth'
import { Button, ButtonBase, ErrorBox } from '@/components/common'
import AuthInput from './AuthInput'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { resolveErrorMessage } from '@/utils'

export default function ResetPasswordForm({ email, setEmail, setView }) {
  const [notice, setNotice] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const router = useRouter()

  const handlePasswordReset = useCallback(
    async (e) => {
      e.preventDefault()

      setLoading(true)
      setHasError(false)

      try {
        await resetPasswordEmail(email)
        setNotice('A reset link has been sent to your email.')
        SegmentHandler.track('Auth Completed', {
          location: router.asPath,
          value: 'resetPassword',
        })
      } catch (err) {
        setHasError(true)
        setNotice(resolveErrorMessage(err))
      } finally {
        setLoading(false)
      }
    },
    [email, router.asPath]
  )

  return (
    <form
      onSubmit={handlePasswordReset}
      className="flex flex-col items-center justify-start w-full"
    >
      <ErrorBox
        errorText={notice}
        color={hasError ? 'red' : 'yellow'}
        className="mb-4"
      />

      <AuthInput
        name="email"
        type="email"
        placeholder="EMAIL"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        className="w-full rounded-full mt-4 mb-2"
        type="submit"
        disabled={!email || loading}
      >
        Reset Password
      </Button>

      <ButtonBase
        className="text-primary"
        onClick={() => setView('login')}
        disabled={loading}
        type="button"
      >
        Nevermind
      </ButtonBase>
    </form>
  )
}
