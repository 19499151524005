import React, { useEffect, useMemo } from 'react'
import {
  createInstance,
  OptimizelyProvider as Provider,
  setLogLevel,
} from '@optimizely/react-sdk'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { detectDevice } from '@/utils'
import { OptimizelyWindow } from './types'

interface Props {
  children: React.ReactNode
}

setLogLevel('error')

const optimizelyClient = createInstance({
  sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_KEY,
})

if (
  typeof window !== 'undefined' &&
  !(window as OptimizelyWindow).optimizelyClientInstance
) {
  const win = window as OptimizelyWindow
  win.optimizelyClientInstance = optimizelyClient
}

const OptimizelyProvider = ({ children }: Props) => {
  const { anonymousId } = SegmentHandler.getState().user
  const user = useMemo(() => ({ id: anonymousId }), [anonymousId])

  useEffect(() => {
    SegmentHandler.identify({
      userAgent: window.navigator.userAgent,
      device: detectDevice(),
    })
  }, [])

  return (
    <Provider
      optimizely={optimizelyClient}
      user={user}
      isServerSide={typeof window === 'undefined'}
    >
      {children}
    </Provider>
  )
}

export default OptimizelyProvider
