import React from 'react'
import classNames from 'classnames'
import { Warning } from '../svg'

export const colorHash = {
  red: 'border-core-error-700 bg-core-error-700',
  yellow: 'bg-core-warning-500 border-core-warning-500',
}

interface Props {
  errorText?: string | null
  color?: 'red' | 'yellow'
  size?: 'lg' | 'sm'
  className?: string
}

const ErrorBox: React.FC<Props> = ({
  errorText,
  color = 'red',
  size = 'lg',
  className,
}) => {
  if (!errorText) return null

  return (
    <div
      className={classNames(
        {
          'text-lg rounded text-core-gray-950 bg-opacity-10 flex flex-row items-center':
            true,
          'p-2.5 border-2': size === 'lg',
          'p-1.5 border': size === 'sm',
          [colorHash[color]]: true,
        },
        className
      )}
    >
      <div className="h-full flex flex-col items-center justify-center">
        <Warning
          className={classNames({
            'text-core-warning-500': color === 'yellow',
            'text-core-error-700': color === 'red',
            'text-2xl': size === 'lg',
            'text-xl': size === 'sm',
          })}
        />
      </div>
      <span
        className={classNames({
          'ml-2': true,
          'text-sm': size === 'lg',
          'text-xs': size === 'sm',
        })}
      >
        {errorText}
      </span>
    </div>
  )
}

export default ErrorBox
