import React from 'react'
import Progress from './Progress'
import { Campaign } from '@/services/api/campaign'
import PriorRaises from './PriorRaises'
import SellingFast from './SellingFast'
import Footer from './Footer'
import Type from '../Type'

interface Props {
  campaign: Campaign
  url: string
}

const CurrentlyFundingSection: React.FC<Props> = ({ campaign, url }) => {
  const clarificationSymbol = campaign.isSellingFast ? '†' : ''

  return (
    <>
      <Progress campaign={campaign} />
      <PriorRaises campaign={campaign} />
      <SellingFast campaign={campaign} className="text-core-gray-700" />

      <Footer cta="Back This Project" url={url}>
        <Type as="p" variant="caption-sm" className="text-core-gray-600">
          {Number(campaign.backers).toLocaleString()} investors
        </Type>
        <Type as="p" variant="caption-sm" className="text-core-gray-600">
          {Number(campaign.timeRemaining.timeRemainingValue).toLocaleString()}
          <span className="lowercase ml-1">
            {`${campaign.timeRemaining.timeRemainingDimension} left`}{' '}
            {clarificationSymbol}
          </span>
        </Type>
      </Footer>
    </>
  )
}

export default CurrentlyFundingSection
