import { AngelRoundIcon } from '@/components/svg'
import { InvestmentChat } from '@/services/api/chatbot'
import { formatMoney } from '@/utils'
import { Transition } from '@headlessui/react'
import { format } from 'date-fns'
import React from 'react'
import Type from '../Type'

interface Props {
  message: InvestmentChat
}

const Message: React.FC<Props> = ({ message }) => {
  const investmentDate =
    typeof message.completed === 'number'
      ? new Date(message.completed * 1000)
      : null

  return (
    <Transition
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      appear
      show
    >
      <Type
        as="p"
        variant="paragraph-sm"
        className="mb-4 flex items-center text-core-gray-800"
      >
        <AngelRoundIcon className="mr-1 text-core-gray-900 flex-shrink-0" />
        <span>
          {message.firstName === 'Anonymous' ? 'Someone' : message.firstName}{' '}
          from {message.state} invested{' '}
          {formatMoney(message.amount, {
            zeroFractionDigits: true,
          })}
          !{' '}
          {investmentDate && (
            <span className="text-core-gray-400 whitespace-nowrap">
              {format(investmentDate, 'Pp')}
            </span>
          )}
        </span>
      </Type>
    </Transition>
  )
}

export default Message
