import React, { useCallback, useState, useEffect } from 'react'
import { SiFacebook } from 'react-icons/si'
import { facebookLogin } from '@/services/api/auth'
import { Button } from '@/components/common'
import useAuth from '@/services/hooks/useAuth'
import { resolveErrorMessage, report } from '@/utils'
import { NotifiableError } from '@bugsnag/js'
import { loginWithFacebook, loadFacebookScript } from './utils'

export interface Props {
  setError: (errMessage: string) => void
  className?: string
}

const FacebookAuthButton: React.FC<Props> = ({ setError, className }) => {
  const [initError, setInitError] = useState('')
  const [loading, setLoading] = useState(false)
  const { persistUser, closeModal, redirect } = useAuth()

  const onSuccess = useCallback(
    async (accessToken) => {
      setLoading(true)

      try {
        const res = await facebookLogin(accessToken)
        persistUser(res.uuid, res.authorization, null, redirect)
        closeModal()
      } catch (err) {
        report.log(err as NotifiableError)
        setError(resolveErrorMessage(err as Error))
      } finally {
        setLoading(false)
      }
    },
    [persistUser, closeModal, redirect, setError]
  )

  const onClick = useCallback(async () => {
    if (initError) {
      setInitError(initError)
    } else {
      setError('')
      setLoading(true)
      try {
        const accessToken = await loginWithFacebook()
        onSuccess(accessToken)
      } catch (err) {
        report.log(err as NotifiableError)
        setError(resolveErrorMessage(err as Error))
      } finally {
        setLoading(false)
      }
    }
  }, [setError, initError, onSuccess])

  const initialize = useCallback(async () => {
    setLoading(true)
    try {
      await loadFacebookScript()
    } catch (err) {
      setError(resolveErrorMessage(err as Error))
    } finally {
      setLoading(false)
    }
  }, [setError])

  useEffect(() => {
    initialize()
  }, [initialize])

  return (
    <div className={className}>
      <Button
        size="sm"
        color="gray"
        className="w-full flex flex-row items-center justify-center w-full relative"
        onClick={onClick}
        disabled={loading}
      >
        <SiFacebook className="text-md mr-2" />
        <span>Login with Facebook</span>
      </Button>
    </div>
  )
}
export default FacebookAuthButton
