import React from 'react'
import { ISocialShareBlockFields } from 'src/types/generated/contentful'
import { Campaign } from '@/services/api/campaign'
import Classic from './Classic'
import Current from './CampaignShareBox'

interface Props {
  campaign: Campaign
  content?: ISocialShareBlockFields
  className?: string
}

const CampaignShareBox: React.FC<Props> = ({
  campaign,
  content,
  className,
}) => {
  if (content) {
    return (
      <Current
        campaignName={campaign.name}
        campaignSlug={campaign.slug}
        content={content}
        className={className}
      />
    )
  }
  return <Classic campaign={campaign} className={className} />
}

export default CampaignShareBox
