import { SWRConfiguration } from 'swr'
import useAuth from '@/services/hooks/useAuth'
import { getUserIVAs, IVA, keyGen } from '@/services/api/IVA'
import useApi from '../useApiV2'

const useUserIVAs = (config?: SWRConfiguration) => {
  const { user } = useAuth()
  const key = keyGen.getUserIVAs(user)

  const { data, ...rest } = useApi<IVA[]>({
    key,
    request: getUserIVAs,
    config,
  })

  return { IVAs: data, ...rest }
}

export default useUserIVAs
