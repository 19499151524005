import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { checkImageAsync } from '@/utils'
import { VideoService } from '@/components/common/VideoBox/types'

interface Props {
  video: string
  size?: 'sm' | 'lg'
  disabled?: boolean
  service: VideoService
}

const useVideoBackgroundImage = ({
  video,
  size = 'lg',
  disabled = false,
  service,
}: Props) => {
  const [isError, setError] = useState(false)
  const [src, setSrc] = useState<string>()

  const getImgSrc = useCallback(async () => {
    if (service === 'youtube') {
      return `https://img.youtube.com/vi/${video}/${
        size === 'sm' ? '1' : 'maxresdefault'
      }.jpg`
    }

    if (service === 'vimeo') {
      const res = await axios.get(
        `https://vimeo.com/api/v2/video/${video}.json`
      )
      if (size === 'sm') return res.data[0]?.thumbnail_small
      return res.data[0]?.thumbnail_large
    }
  }, [video, service, size])

  useEffect(() => {
    const checkImage = async () => {
      try {
        setError(false)
        const src = await getImgSrc()
        await checkImageAsync(src)
        setSrc(src)
      } catch (e) {
        setError(true)
      }
    }

    if (disabled) {
      setSrc(undefined)
    } else if (video) {
      checkImage()
    }
  }, [disabled, service, video, getImgSrc])

  return {
    src,
    isLoading: !src && !isError,
    isError,
  }
}

export default useVideoBackgroundImage
