import { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { identify as FullStoryIdentify } from '@fullstory/browser'
import useAuth from '@/services/hooks/useAuth'

const Identify = () => {
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      SegmentHandler.identify({ email: user.email }, user.id)
      Bugsnag.setUser(user.id)
      FullStoryIdentify(user.id, {
        email: user.email,
        displayName:
          user.displayName || `${user.firstName} ${user.lastName}`.trim(),
      })
    }

    // TODO: do we need to un-identify when user === null?
  }, [user])

  return null
}

export default Identify
