import { Profile } from '@/components/svg'
import classNames from 'classnames'
import React from 'react'

interface Props {
  avatarUrl?: string
  className?: string
  name: string
}

const UserAvatar: React.FC<Props> = ({ avatarUrl, className, name }) => {
  return (
    <span
      className={classNames(
        'w-8 h-8 bg-oxide-3 rounded-full flex items-center justify-center text-white text-lg',
        className
      )}
    >
      {avatarUrl ? (
        <img src={avatarUrl} alt="avatar" className="w-2/3" />
      ) : name === 'Anonymous' || name === undefined ? (
        <Profile />
      ) : (
        name[0]
      )}
    </span>
  )
}

export default UserAvatar
