import React from 'react'
import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'
import Switch from '@/components/common/Switch/Switch'
import Text from '@/components/common/Text/Text'

export interface Props {
  id: string
  name: string
  text: string | JSX.Element
  className?: string
  onChangeCallback?: () => void
}

const ToggleInput: React.FC<Props> = ({
  id,
  text,
  name,
  className,
  onChangeCallback,
}) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  return (
    <Switch.Group>
      <div
        id={id}
        data-value={field.value}
        className={classNames(
          {
            'flex flex-row items-center w-full space-x-2.5': true,
          },
          className
        )}
      >
        <Text as="div" preset="body.md" className="flex-1">
          {text}
        </Text>

        <Switch
          onText="YES"
          offText="NO"
          checked={!!field.value}
          onChange={() => {
            setFieldValue(name, !field.value)
            onChangeCallback && onChangeCallback()
          }}
          error={meta.touched && !!meta.error}
          testId={name}
        />

        {/* Hidden input so this toggle can be scrolled to if error */}
        <input type="hidden" name={name} />
      </div>
    </Switch.Group>
  )
}

export default ToggleInput
