import { useEffect, useMemo } from 'react'
import Router, { useRouter } from 'next/router'
import useNotifications from '@/services/hooks/useNotifications'
import useUserInvestedCampaigns from '@/services/hooks/useUserInvestedCampaigns'
import useAuth from '@/services/hooks/useAuth'
import useInvestments from '@/services/hooks/useInvestments'
import useUserIVAs from '@/services/hooks/useUserIVAs/useUserIVAs'
import { Investment } from '@/services/api/investment'

const swrConfig = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

type ParsedInvestment = Pick<
  Investment,
  'id' | 'campaign' | 'completed' | 'canceled'
>

const disabledRoutes = ['/invest/', '/oauth/', '/investments', '/issuer']

export const BellNotificationSignal: React.FC = () => {
  const router = useRouter()
  const disabled = useMemo(
    () => disabledRoutes.some((route) => router.asPath.includes(route)),
    [router]
  )

  const { addNotification, removeNotification, updateNotification } =
    useNotifications()
  const { userInvestedCampaigns: campaigns } = useUserInvestedCampaigns()

  const { IVAs = [] } = useUserIVAs(swrConfig)

  const { investments = [] } = useInvestments(swrConfig)

  const jsonInvestments = JSON.stringify(
    investments
      .map(({ id, completed, canceled, campaign }) => ({
        id,
        completed,
        canceled,
        campaign,
      }))
      .sort((a, b) => a.id.localeCompare(b.id))
  )

  useEffect(() => {
    const parsedInvestments: ParsedInvestment[] = JSON.parse(jsonInvestments)

    if (!parsedInvestments.length || !campaigns || disabled) return

    parsedInvestments
      .filter((i) => !i.completed && !i.canceled)
      .forEach((i) => {
        const campaign = campaigns.find((c) => c.slug === i.campaign)
        if (campaign?.status !== 'currently_funding') return

        addNotification({
          id: `incomplete-investment-${i.id}`,
          type: 'bell',
          text: `Finish your investment in ${campaign.name}`,
          onClick: () => {
            Router.push(`/invest/${campaign.slug}/${i.id}`)
          },
          seen: false,
        })
      })
  }, [jsonInvestments, campaigns, addNotification, disabled])

  useEffect(() => {
    const parsedInvestments: ParsedInvestment[] = JSON.parse(jsonInvestments)

    if (!IVAs.length || !parsedInvestments.length || !campaigns || disabled)
      return

    IVAs.forEach((IVA) => {
      const bellId = `iva-bell-${IVA.id}`
      const toastId = `iva-toast-${IVA.id}`
      const investment = parsedInvestments.find(
        (i) => i.id === IVA.investmentId
      )
      const campaign = campaigns.find((c) => c.slug === investment?.campaign)
      const text = `We need some information to complete your investment in ${campaign?.name}`

      if (
        campaign?.status !== 'currently_funding' ||
        IVA?.status !== 'Pending User Action'
      )
        return

      addNotification({
        id: bellId,
        type: 'bell',
        text,
        onClick: () => {
          // if taking action, remove associated toast notification
          Router.push(`/investments/${IVA.investmentId}/verify`)
          removeNotification(toastId)
        },
        seen: false,
      })

      addNotification({
        id: toastId,
        type: 'toast',
        variant: 'error',
        text,
        actions: [
          {
            label: 'Update',
            onClick: () => {
              // if taking action, remove associated bell notification
              Router.push(`/investments/${IVA.investmentId}/verify`)
              updateNotification(bellId, { seen: true })
            },
          },
        ],
      })
    })
  }, [
    IVAs,
    jsonInvestments,
    campaigns,
    addNotification,
    removeNotification,
    updateNotification,
    disabled,
  ])

  return null
}

const GatedNotificationSignal = () => {
  const { user } = useAuth()
  if (!user) return null
  return <BellNotificationSignal />
}

export default GatedNotificationSignal
