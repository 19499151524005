import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { MarkdownModal, Text } from '@/components/common'
import { AngelFundingLogo } from '@/components/svg'
import MenuItem from './MenuItem'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import InterstitialLink from '@/components/InterstitialLink'
import { useRouter } from 'next/router'
import { getNextRouterQueryParam } from '@/utils'

const Footer: React.FC = () => {
  const router = useRouter()
  const [modalContent, setModalContent] = useState<string | undefined>()
  const [modalHeading, setModalHeading] = useState<string | undefined>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const siteAssets = useSiteAssets()

  const currentYear = new Date().getFullYear()

  const openModal = (content?: string, heading?: string) => {
    setModalContent(content)
    setModalHeading(heading)
    setIsModalOpen(true)
  }

  useEffect(() => {
    const value = getNextRouterQueryParam('education', router)
    if (value === 'true' && siteAssets.howCrowdfundingWorks) {
      openModal(siteAssets.howCrowdfundingWorks)
    }
  }, [router, siteAssets.howCrowdfundingWorks])

  return (
    <footer className="font-whitney bg-black text-white text-xs pt-12 pb-4 px-2 md:pb-12 md:px-4">
      <div className="flex flex-col md:flex-row justify-center items-center text-center">
        <div className="inline-flex flex-col items-center">
          {siteAssets.isWhiteLabel ? (
            <InterstitialLink domain="invest.angel.com">
              <Text as="p" preset="body.xs" className="mb-2">
                Powered by
              </Text>
              <AngelFundingLogo
                className="h-[40px]"
                fill1="#fff"
                fill2="#fff"
              />
            </InterstitialLink>
          ) : (
            <Link href="/">
              <AngelFundingLogo
                className="h-[40px]"
                fill1="#fff"
                fill2="#fff"
              />
            </Link>
          )}
          <Text as="p" preset="custom" className="mt-4 hidden md:block">
            COPYRIGHT © {currentYear}, ANGEL FUNDING.
          </Text>
          <Text as="p" preset="custom" className="hidden md:block">
            ALL RIGHTS RESERVED.
          </Text>
        </div>

        <menu className="inline-flex flex-col md:flex-row justify-center flex-wrap text-base text-center mt-5 md:mt-0 md:ml-6">
          <MenuItem
            label="Privacy"
            showItem={!!siteAssets?.privacy}
            onClick={() => openModal(siteAssets?.privacy, 'Privacy')}
          />
          <MenuItem
            label="Terms of Service"
            showItem={
              !!(siteAssets?.termsOfServiceIntro || siteAssets?.termsOfService)
            }
            onClick={() =>
              openModal(
                [
                  siteAssets?.termsOfServiceIntro,
                  siteAssets?.termsOfService,
                ].join('\n'),
                'Terms of Service'
              )
            }
          />
          <MenuItem
            label="How Crowdfunding Works"
            showItem={!!siteAssets?.howCrowdfundingWorks}
            onClick={() => openModal(siteAssets?.howCrowdfundingWorks)}
          />
          <MenuItem
            label="Electronic Consent"
            showItem={!!siteAssets?.electronicConsent}
            onClick={() => openModal(siteAssets?.electronicConsent)}
          />
          <MenuItem
            label="Portal Disclosures"
            showItem={!!siteAssets?.portalDisclosures}
            onClick={() => openModal(siteAssets?.portalDisclosures)}
          />
        </menu>

        <Text as="p" preset="custom" className="mt-4 md:hidden">
          COPYRIGHT © {currentYear}, ANGEL FUNDING.
        </Text>
        <Text as="p" preset="custom" className="md:hidden">
          ALL RIGHTS RESERVED.
        </Text>
      </div>

      <Text
        as="p"
        preset="body.sm"
        className="mt-10 max-w-4xl mx-auto text-center px-4 whitespace-pre-wrap break-words"
      >
        {siteAssets?.footerDisclaimer}
      </Text>

      {modalContent && (
        <MarkdownModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          info={modalContent}
          heading={modalHeading}
        />
      )}
    </footer>
  )
}

export default Footer
