import { Campaign } from '@/services/api/campaign'
import React from 'react'
import Type from '../Type'
import Footer from './Footer'
import PriorRaises from './PriorRaises'

interface Props {
  campaign: Campaign
  url: string
}

const TestingTheWatersSection: React.FC<Props> = ({ campaign, url }) => {
  return (
    <>
      <Type as="p" variant="title-xs" className="text-core-gray-900">
        <Type as="span" variant="title-lg">
          {Number(campaign.backers).toLocaleString()}
        </Type>{' '}
        People expressed interest
      </Type>

      <Footer cta="Express Interest" url={url} ctaVariant="link">
        <PriorRaises campaign={campaign} />
      </Footer>
    </>
  )
}

export default TestingTheWatersSection
